.alert-success {
  color: #18760a;
  background-color: #e9f7e5;
}
.alert {
  position: relative;
  padding: 0.25rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  right: 91rem;
  bottom: 10rem;
  font-size: $text-lg;
  font-weight: normal;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}
.material-container {
  height: 44rem;
  position: relative;
  .material {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2rem 3.75rem 1rem 3.75rem;
    font: normal normal 600 $text-xlPlusTwo $text-font-family;

    .material-left {
      text-align: left;
      font-weight: 600;
      font-size: $text-xl;
      letter-spacing: 0px;
      color: $primary-black;
      text-transform: capitalize;
    }

    .newMaterialBtn {
      text-transform: none;
      background-color: $primary-green;
      text-align: left;
      font-size: $text-lg;
      font-family: $text-font-family;
      letter-spacing: 0px;
      color: $primary-white;
      border-radius: 21px;
      padding: 6px 16px;
    }

    .MaterialBack {
      cursor: pointer;
      display: flex;
      align-items: center;
      img {
        margin-right: 10px;
      }
    }
    .dialog-box {
      width: 50%;
      height: 50%;
    }
  }

  .materialList_table_container {
    margin: 0.5rem 3.75rem 1rem 3.75rem;
    width: 92%;
    .materialList_table {
      .materialListBody {
        .bodyCell {
          padding: 1rem;
          text-align: left;
          color: #2f3543;
          font-size: $text-lg;
          font-family: $text-font-family;
          letter-spacing: 0px;
          max-width: 12.5rem;
        }
        .bodyCell.titleCell {
          font-weight: 600;
        }

        .bodyCell.overflowHidden {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .materialListHeader {
        .headerCell {
          text-align: left;
          font-weight: 600;
          font-size: $text-lg;
          font-family: $text-font-family;
          letter-spacing: 0px;
          color: $primary-gray;

          svg {
            margin-left: 0.75rem;
          }
        }
      }
    }
  }
}
.materialRedBox {
  background: $primary-white 0% 0% no-repeat padding-box;
  border: 1px solid #cf2a2a;
  border-radius: 6px;
  opacity: 1;
  outline: none;
  .dropDrag {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    font-weight: 450;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 9px;
    border-color: #adadad6e;
    border-style: dashed;
    background-color: #f7f8fc;
    color: $gray;
    outline: none;
    width: 94%;
    transition: border 0.24s ease-in-out;
    margin-left: 3%;
    margin-top: 3%;
    margin-bottom: 3%;
    .progressbar {
      background: #cdc9c9;
      border-radius: 11px;
    }
  }
}
.materialText-box {
  background: $primary-white 0% 0% no-repeat padding-box;
  border: 1px solid #b9b9b9;
  border-radius: 6px;
  opacity: 1;
  outline: none;
  .fileUpload {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 45px;
    border-width: 2px;
    border-radius: 9px;
    border-color: #adadad6e;
    border-style: dashed;
    background-color: #f7f8fc;
    color: #1447d4;
    text-decoration: underline;
    outline: none;
    height: 115px;
    width: 620px;
    transition: border 0.24s ease-in-out;
    margin-left: 24px;
    margin-top: 3%;
    margin-bottom: 3%;
    margin-right: 24px;
    .crossbtnUploadFile {
      margin-right: 25px;
      color: #212121;
      width: 19px;
      height: 19px;
    }
    .progressbars {
      width: 471px;
      height: 4px;
      background: $primary-green 0% 0% no-repeat padding-box;
      border-radius: 11px;
      opacity: 1;
    }
  }
  .dropDrag {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 9px;
    border-color: #adadad6e;
    border-style: dashed;
    background-color: #f7f8fc;
    color: $gray;
    outline: none;
    width: 94%;
    transition: border 0.24s ease-in-out;
    margin-left: 3%;
    margin-top: 3%;
    margin-bottom: 3%;
    height: 120px;

    .dropDragText {
      padding-top: 25px;
      margin-bottom: 0;
      margin-top: 0;
    }
    .dropDragSubTitle {
      padding-top: 8px;
      font-size: $text-xs;
      margin-top: 0;
    }
  }
  .progressbar {
    background: #cdc9c9;
    border-radius: 11px;
  }
  .MuiLinearProgress-barColorPrimary {
    background-color: $primary-green;
  }
}

.material-text {
  text-align: left;
  font: normal normal 600 $text-base $text-font-family;
  letter-spacing: 0px;
  color: #4b4b4b;
  margin-top: 1rem;
  margin-bottom: 0.25rem;
}

.material-title {
  color: #cf2a2a;
}
.material-errorFileSize {
  color: #cf2a2a;
  font-size: $text-xs;
  margin-left: 2.125rem;
}
.material-error {
  color: #cf2a2a;
  font-size: $text-xs;
}

.uploadbtn {
  height: 50px;
  width: 385px;
  border: 1px solid #b9b9b966;
  border-radius: 6px;
  opacity: 1;
  position: absolute;
  text-align: left;
  font: normal normal 600 $text-lg $text-font-family;
  letter-spacing: 0px;
  color: #1447d4;
  background: linear-gradient(to right, #f0f4f9 37%, white 0%);
  .thumbnailFilename {
    position: relative;
    bottom: 7%;
    font-size: 13px;
    .thumbnailcrossbtn {
      width: 12px;
      position: relative;
      top: 9px;
      left: 5px;
    }
  }
  .fileName {
    margin-left: 5rem;
    font-size: 13px;
    color: #1447d4;
  }
  .defaultfileName {
    margin-left: 5rem;
    font-size: $text-sm;
    color: $gray;
  }
}
.uploadArrow {
  margin-right: 7px;
  margin-left: 14px;
  margin-top: 18px;
}
.logoUpload {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-top: 10px;
}
.backgroundBox {
  top: 72%;
  width: 200px;
  height: 120px;
  position: absolute;
  background: #dfe2e8 0% 0% no-repeat padding-box;
  border: 1px solid #7070704d;
  border-radius: 4px;
  opacity: 1;
  margin-left: 29.5rem;
  .fileInfo {
    position: relative;
    bottom: 32%;
    margin-left: 58px;
    font-size: $text-xs;
    font-style: italic;
    color: $gray;
  }

  .textFile {
    font: normal normal 400 $text-sm $text-font-family;
    letter-spacing: 0px;
    color: #2f3543;
    text-transform: uppercase;
    opacity: 1;
    position: absolute;
    top: 45%;
    left: 35%;
  }
}
.classCircular {
  position: absolute;
  right: 50%;
  top: 50%;
}
.buttonText {
  *:focus {
    outline: 0 !important;
  }
}
.deleteDialog {
  width: 525px;

  .dialogbtnStyle {
    padding-bottom: 1.5rem;
  }
  .titleDelete {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    text-align: left;
    font-size: $text-xl;
    letter-spacing: 0px;
    color: $primary-black;
    opacity: 1;
    padding-left: 36px;
    .crossBtn {
      margin-left: 10px;
    }
  }
  .contentText {
    font-size: $text-base;
    letter-spacing: 0px;
    color: #3d3d3d;
    opacity: 1;
    margin-left: 13px;
    .textBold {
      font-weight: 600;
    }
  }
  .crossBtn {
    position: fixed;
    right: 38%;
  }

  .okBtnStyle {
    border-radius: 21px;
    background-color: $primary-green;
    text-transform: none;
    color: $primary-white;
    font-size: $text-base;
    font-weight: 600;
  }
  .cancelBtnStyle {
    border-radius: 21px;
    background-color: #adadad73;
    text-transform: none;
    color: $primary-black;
    font-size: $text-base;
    font-weight: 600;
  }
  .deleteMaterialDialog {
    position: relative;
    left: 20%;
    bottom: 2%;
  }
}

.dashboard_Alert {
  padding: 0rem 1rem !important;
  position: absolute;
  z-index: 1;
  // width: 99%;
  margin-left: 45%;
}
.viewMaterialContainer {
  padding: 2.25rem;
  min-height: 778px;
  display: flex;
  flex-direction: column !important;
  justify-content: space-between;
  .viewMaterialTitle {
    font-weight: 600;
    color: $primary-black;
    font-size: $text-xl;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
  }

  .UploadfileName {
    margin-top: 7rem;
    color: #1447d4;
    font-size: $text-base;
    margin-right: 5px;
  }
  .viewMaterial {
    color: $primary-gray;
    opacity: 1;
    font-size: $text-sm;
  }
  .viewDescription {
    margin-top: 2rem;
    color: #2f3543;
    font-size: $text-base;
    display: flex;
    justify-content: space-between;

    .viewImage {
      margin-left: 1.5rem;
    }
  }
}

.msg-container {
  height: 44rem;
  position: relative;
  .message {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2rem 3.75rem 1rem 3.75rem;
    font: normal normal 600 $text-xlPlusTwo $text-font-family;

    .message-left {
      text-align: left;
      font-weight: 600;
      font-size: $text-xl;
      letter-spacing: 0px;
      color: $primary-black;
      text-transform: capitalize;
    }

    .newMaterialBtn {
      text-transform: none;
      background-color: $primary-green;
      text-align: left;
      font-size: $text-lg;
      font-family: $text-font-family;
      letter-spacing: 0px;
      color: $primary-white;
      border-radius: 21px;
      padding: 6px 16px;
    }
  }

  .messageList_table_container {
    margin: 0.5rem 3.75rem 1rem 3.75rem;
    width: 92%;
    .messageList_table {
      .messageListBody {
        .bodyCell {
          padding: 1rem;
          text-align: left;
          color: #2f3543;
          font-size: $text-lg;
          font-family: $text-font-family;
          letter-spacing: 0px;
          max-width: 12.5rem;
        }
        .bodyCell.titleCell {
          font-weight: 600;
        }

        .bodyCell.overflowHidden {
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .bodyCell.messageDescription {
          font-size: $text-lg;
          color: $primary-black;
        }
        .editnDel {
          color: #1447d4;
          font-size: $text-lg;
        }
      }
      .messageListHeader {
        .headerCell {
          text-align: left;
          font-weight: 600;
          font-size: $text-lg;
          font-family: $text-font-family;
          letter-spacing: 0px;
          color: $primary-gray;

          svg {
            margin-left: 0.75rem;
          }
        }
      }
    }
  }
}
.primaryColor {
  color: $primary-green;
}
.primaryColorGray {
  color: $primary-gray;
}
.infoCallout {
  color: $gray;
  background-color: $primary-white;
  width: 17px;
  height: 15px;
}

.uploadContentMgs {
  margin-top: 3.25rem;
  font-size: $text-xs;
  color: $gray;
  font-style: italic;
}

.uploadErrorParentContainer {
  margin-top: -2.5rem;
  color: $gray;
}
.progressbarsError {
  width: 471px;
  height: 4px;
  background: $primary-green 0% 0% no-repeat padding-box;
  border-radius: 11px;
  opacity: 1;
}
.fileStyle {
  justify-content: center;
  align-items: center;
}
.errorMessageFileSize {
  padding-left: 35%;
}
.auth-spinner {
  height: 100%;
  width: 100%;
  position: absolute;
}
.css-v3z1wi {
  justify-content: center;
}
