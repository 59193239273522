.appointmentMsgContainer {
  .appointmentMsgTitle {
    padding: 36px 25px 5px 36px;
  }
  .appointmentMsgContent {
    padding: 5px 36px;
    .appointmentMsg-text {
      text-align: left;
      font-size: $text-base;
      letter-spacing: 0px;
      color: #3d3d3d;
      font-weight: 600;
      margin-top: 0.5rem;
      margin-bottom: 0.25rem;
    }
    .appointmentMsgText-box {
      background: $primary-white;
      border: 1px solid #cfc0d4;
      border-radius: 4px;
      outline: none;
    }
    .appointmentMsgText-box2 {
      background: $primary-white;
      box-shadow: 0px 6px 9px #0000000f;
      border: 1px solid #cfc0d4;
      border-radius: 4px;
    }
    .helperTextStyle {
      font-size: $text-xs;
      color: #a0a0a0;
      opacity: 100;
      font-style: normal;
    }
  }
  .btnClick {
    padding: 8px 30px 36px 8px;
  }
}
.appointmentMsg-container {
  height: 44rem;
  position: relative;
  .appointmentMsg {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2rem 3.75rem 1rem 3.75rem;
    font: normal normal 600 $text-xlPlusTwo $text-font-family;

    .appointmentMsg-left {
      text-align: left;
      font-weight: 600;
      font-size: $text-xl;
      letter-spacing: 0px;
      color: $primary-black;
      text-transform: capitalize;
    }

    .newAppointmentBtn {
      text-transform: none;
      background-color: $primary-green;
      text-align: left;
      font-size: $text-lg;
      font-family: $text-font-family;
      letter-spacing: 0px;
      color: $primary-white;
      border-radius: 21px;
    }
  }
  .appointmentMsgList_table_container {
    margin: 0.5rem 3.75rem 1rem 3.75rem;
    width: 92%;
    .appointmentMsgList_table {
      .appointmentMsgListBody {
        .bodyCell {
          padding: 1rem;
          text-align: left;
          color: #2f3543;
          font-size: $text-lg;
          font-family: $text-font-family;
          letter-spacing: 0px;
          max-width: 12.5rem;
        }
        .bodyCell.titleCell {
          font-weight: 600;
        }

        .bodyCell.overflowHidden {
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .bodyCell.appointmentMsgDescription {
          font-size: $text-lg;
          color: $primary-black;
        }
        .editnDel {
          color: #1447d4;
          font-size: $text-lg;
        }
      }
      .appointmentMsgListHeader {
        .headerCell {
          text-align: left;
          font-weight: 600;
          font-size: $text-lg;
          font-family: $text-font-family;
          letter-spacing: 0px;
          color: $primary-gray;

          svg {
            margin-left: 0.75rem;
          }
        }
      }
    }
  }
}
.AppointmentdeleteDialog {
  width: 525px;
  .dialogbtnStyle {
    padding-bottom: 1.5rem;
  }
  .AppointmenttitleDelete {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    text-align: left;
    font-size: $text-xl;
    letter-spacing: 0px;
    color: $primary-black;
    opacity: 1;
    padding-left: 36px;
    .AppointmentcrossBtn {
      margin-left: 10px;
    }
  }
  .AppointmentcontentText {
    font-size: $text-base;
    letter-spacing: 0px;
    color: #3d3d3d;
    opacity: 1;
    margin-left: 13px;
    .AppointmenttextBold {
      font-weight: 600;
    }
  }
  .AppointmentcrossBtn {
    position: fixed;
    right: 38%;
  }

  .AppointmentokBtnStyle {
    border-radius: 21px;
    background-color: $primary-green;
    text-transform: none;
    color: $primary-white;
    font-size: $text-base;
    font-weight: 600;
  }
  .AppointmentcancelBtnStyle {
    border-radius: 21px;
    background-color: #adadad73;
    text-transform: none;
    color: $primary-black;
    font-size: $text-base;
    font-weight: 600;
  }
  .deleteAppointmentDialog {
    position: relative;
    left: 20%;
    bottom: 2%;
  }
}
