.summary{
    padding: 16px 60px 16px 60px;

   .totalApt {
    margin: 0px;
    font: normal normal normal $text-lg $text-font-family;
    }
    .cardBase{
        background: $ghost_white 0% 0% no-repeat padding-box;
        display: flex;
        overflow: auto;
        padding:32px  0px 32px  0px ;
        margin-top: 30px;
      
    }
    .cards{
        border-radius: 0.5rem;
        background-color: $primary-green;
        border: 1px solid $burgundy;
        overflow:visible;
        margin-left: 20px;
        .cardContent{
            align-items: center;
            display: flex;
            justify-content: center;
            flex-direction: column;
        .statusCount{
            font: normal normal 600 $text-3xl $text-font-family;
            color:$primary-white;
        }
        .statusType{
            font: normal normal normal $text-xl $text-font-family;
            color: $primary-white;
        }
        }
       
    }
    .cardDeselected{
        border-radius: 0.5rem;
        background-color: $primary-white;
        border: 1px solid $solitute;
        overflow:visible;
        margin-left: 20px;
        .cardContent{
            align-items: center;
            display: flex;
            justify-content: center;
            flex-direction: column;
        .statusCount{
            font: normal normal 600 $text-3xl $text-font-family;
            color: $labelcolor;
        }
        .statusType{
            font: normal normal normal $text-xl $text-font-family;
            color: $nightRider;
        }
        }
    }


    #demo-customized-button{ 
        min-width: 230px;
        color:  $labelcolor;
        background-color: $primary-white;
        border: 1px solid $coolBlue;
        font: normal normal 600 $text-base $text-font-family;
        text-transform: capitalize;
        padding: 6px 16px;
        border-radius: 4px;
        span:first-of-type{
        margin-left: auto;
    }
}

   

}