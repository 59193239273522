.systemSettings_table_container {
  margin: 0.5rem 3.75rem 1rem 3.75rem;
  width: 92%;
  .systemSettings_table {
    .systemSettingsBody {
      .activeRow {
        background-color: #f7f8fc;
      }
      .bodyCell {
        padding: 1rem;
        text-align: left;
        color: #2f3543;
        font-size: $text-lg;
        font-family: $text-font-family;
        letter-spacing: 0px;
        max-width: 12.5rem;

        button {
          text-transform: none;
          cursor: pointer;
        }
      }
      .bodyCell.titleCell {
        font-weight: 600;
      }

      .bodyCell.overflowHidden {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .systemSettingsHeader {
      .headerCell {
        text-align: left;
        font-weight: 600;
        font-size: $text-lg;
        font-family: $text-font-family;
        letter-spacing: 0px;
        color: $primary-gray;

        svg {
          margin-left: 0.75rem;
        }
      }
    }
  }
}

#editSystemSettings-dialog {
  .editSystemSettings-dialog-container {
    padding: 2.25rem;
    border-radius: 0%;
    .editSystemSettings-dialog-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.5rem;

      span {
        font-size: $text-xl;
        font-weight: 600;
        letter-spacing: 0px;
        color: $primary-black;
      }

      svg {
        cursor: pointer;
        color: $primary-gray;
      }
    }

    .editSystemSettings-dialog-content {
      .editSystemSettings-dialog-description {
        p:first-child {
          text-align: left;
          font-size: $text-base;
          font-weight: 600;
          letter-spacing: 0px;
          color: #3d3d3d;
          margin-block-start: 0em;
          margin-block-end: 0em;
        }
        p:last-child {
          font-size: $text-base;
          text-align: left;
          letter-spacing: 0px;
          color: $primary-gray;
          margin-block-start: 0.5rem;
          margin-block-end: 1rem;
        }
      }
    }

    .editSystemSettings-duration {
      .MuiFormControl-root {
        min-width: 18.5rem;
        max-height: 3rem;

        .MuiOutlinedInput-root {
          max-height: 3rem;
        }
      }
      label {
        font-size: $text-base;
        font-weight: 600;
        text-align: left;
        letter-spacing: 0px;
        color: #3d3d3d;
        margin-bottom: 0.25rem;

        sup {
          color: #cf2a2a;
        }
      }
    }
    .editSystemSettings-dialog-BtnContainer {
      padding: 0px;
      margin-top: 1.25rem;
      button {
        border-radius: 21px;
        text-transform: none;
        height: 2.625rem;
        font-size: $text-base;
      }

      button.cancelBtn {
        color: $primary-black;
        background-color: #dfe2e8;
        margin-right: 1.25rem;
      }

      button.saveBtn {
        color: $primary-white;
        background-color: $primary-green;
      }
    }
  }
}

.meetingSettings {
  .meetingSettings_table_container {
    margin: 0.5rem 3.75rem 1rem 3.75rem;
    width: 92%;

    .meetingSettings_table {
      .meetingSettingssHeader {
        .headerCell {
          text-align: left;
          font-size: $text-lg;
          font-family: $text-font-family;
          letter-spacing: 0px;
          color: $primary-gray;
          text-transform: capitalize;
          opacity: 0.8;
          border-bottom: none;
          font-weight: 400;
        }
      }
      .meetingSettingsBody {
        .bodyCell {
          border-bottom: none;
          text-align: left;
          font-size: $text-base;
          font-family: $text-font-family;
          letter-spacing: 0px;
          color: #2f3543;
          padding: 0.75rem;
          .editnDel {
            color: #1447d4;
            font-size: $text-lg;
          }
        }
        .editnDel {
          color: #1447d4;
          font-size: $text-lg;
        }

        .participantcount {
          height: 2.5rem;
          width: 6rem;
        }
      }
    }
  }
}
