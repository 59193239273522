.dashboard_Container {
  display: flex;
  position: relative;
  margin-left: 17%;
}
.dashboard_sidebar {
  height: 100%;
  width: 17%;
  background-color: #f7f8fc;
  position: fixed;
  float: left;
  padding: 6rem 0rem;
  padding-bottom: 283px;
  border: 2px solid #f7f8fc;
  border-right: 1px solid rgb(112, 112, 112, 0.18);
  .sidebar_parent_container {
    text-align: left;
    font-size: $text-xl;
    font-weight: 600;
    letter-spacing: 0px;
    color: $gray;
    .sidebar_parent {
      height: 4.125rem;
      text-transform: uppercase;
      font-size: $text-lg;
      color: #767676;
      padding: 1rem 0rem;
      display: flex;
      align-items: center;
      padding-left: 2rem;
      svg {
        margin-right: 1rem;
      }
    }
    .parentActive {
      color: $primary-black !important;
      svg {
        path {
          stroke: $primary-black;
        }
        circle {
          stroke: $primary-black;
        }
      }
    }

    .parentActiveSystemState {
      color: $primary-black !important;
      svg {
        path {
          fill: $primary-black;
        }
      }
    }

    .sidebar_child {
      height: 4.125rem;
      padding: 1rem 0rem;
      display: flex;
      align-items: center;
      padding-left: 4.35rem;
      font: normal normal normal 20px/27px Segoe UI;
      cursor: pointer;
      svg {
        margin-right: 1rem;
      }
    }
    .sidebar_child.active {
      svg {
        path {
          fill: #1447d4;
        }
        g {
          path {
            stroke: #1447d4;
            fill: #1447d4;
          }
        }
        g {
          rect {
            stroke: #1447d4;
            fill: #1447d4;
          }
        }
      }
    }

    .sidebar_child_Notify {
      height: 4.125rem;
      padding: 1rem 0rem;
      display: flex;
      align-items: center;
      padding-left: 4.35rem;
      font: normal normal normal 20px/27px Segoe UI;
      cursor: pointer;
      svg {
        margin-right: 1rem;
      }
    }

    .sidebar_child_Notify.active {
      svg {
        path {
        }
        g {
          path {
            stroke: #1447d4;
          }
        }
        g {
          rect {
            stroke: #1447d4;
          }
        }
      }
    }

    .active {
      background-color: #e6eaf8;
      color: #1447d4;
      border-right: 4px solid $primary-blue;
      font: normal normal 600 20px/27px Segoe UI;
    }
  }
}
