.msgContainer {
  .msgTitle {
    padding: 36px 25px 5px 36px;
  }
  .msgContent {
    padding: 5px 36px;
    .msg-text {
      text-align: left;
      font-size: $text-base;
      letter-spacing: 0px;
      color: #3d3d3d;
      font-weight: 600;
      margin-top: 0.5rem;
      margin-bottom: 0.25rem;
    }
    .msgText-box {
      background: $primary-white;
      border: 1px solid #cfc0d4;
      border-radius: 4px;
      outline: none;
    }
    .msgText-box2 {
      background: $primary-white;
      box-shadow: 0px 6px 9px #0000000f;
      border: 1px solid #cfc0d4;
      border-radius: 4px;
    }
    .helperTextStyle {
      font-size: $text-xs;
      color: #a0a0a0;
      opacity: 100;
      font-style: normal;
    }
  }
  .btnClick {
    padding: 8px 30px 36px 8px;
  }
}
