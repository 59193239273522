.layout {
    margin-top: 125px;
    margin-left: 41px;
    .header {
        padding-top: 30px;
        font: normal normal 600 20px/27px Segoe UI;
        letter-spacing: 0px;
        color: #0C2E70;
    }
    .layoutStyle {
        margin-top: 24px;
        p {
            font: normal normal normal 16px/21px Segoe UI;
            letter-spacing: 0px;
            color: #3D3D3D;
        }
        .layoutBox {
            display: flex;
            flex-wrap: wrap;
            .container {
                .layoutContainer {
                    float: left;
                    width: 340px;
                    height: 202px;
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    border: 2px solid #E6EAF8;
                    margin-right: 32px;
                    margin-bottom: 20px;
                    &:hover {
                        cursor: pointer;
                    }
                }
                .layoutActive {
                    float: left;
                    width: 340px;
                    height: 202px;
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    border: 2px solid #0C2E70;
                    margin-right: 32px;
                    margin-bottom: 20px;
                    &:hover {
                        cursor: pointer;
                    }
                }
                p.text {
                    font: normal normal normal 20px/20px Segoe UI;
                    letter-spacing: 0px;
                    color: #000000;
                    opacity: 1;
                    text-align: center;
                    margin-bottom: 53px;
                }
                .activeText {
                    font: normal normal 600 20px/20px Segoe UI;
                    letter-spacing: 0px;
                    color: #000000;
                    opacity: 1;
                    text-align: center;
                    margin-bottom: 53px;
                }
                .minimalLayout {
                    width: 260px;
                    height: 26px;
                    background: #E6EAF8 0% 0% no-repeat padding-box;
                    opacity: 1;
                    margin-top: 88px;
                    margin-left: 40px;
                }
                .compactLayout {
                    width: 260px;
                    height: 54px;
                    background: #E6EAF8 0% 0% no-repeat padding-box;
                    border-radius: 4px;
                    opacity: 1;
                    margin-top: 74px;
                    margin-left: 40px;
                }
                .standardLayout {
                    width: 260px;
                    height: 54px;
                    background: #E6EAF8 0% 0% no-repeat padding-box;
                    border-radius: 4px;
                    opacity: 1;
                    align-items: center;
                    display: flex;
                    margin-top: 74px;
                    margin-left: 40px;
                    flex-wrap: wrap;
                    .square {
                        width: 35px;
                        height: 32px;
                        background: #C8D0E0 0% 0% no-repeat padding-box;
                        border-radius: 2px;
                        opacity: 1;
                        margin-top: 7px;
                        margin-left: 20px;
                    } 
                    .line {
                        margin-left: 6px;
                        width: 180px;
                        height: 0px;
                        border: 1px solid #3D3D3D;
                        opacity: 1;
                        margin-top: -3px;
                    }  
                    .dottedLine {
                        width: 143px;
                        border: 1px dashed #3D3D3D;
                        opacity: 1;
                        margin-left: 62px;
                        margin-top: -35px;
                    }             
                }
                .extendedLayout {
                    width: 260px;
                    height: 54px;
                    background: #E6EAF8 0% 0% no-repeat padding-box;
                    border-radius: 4px;
                    opacity: 1;
                    align-items: center;
                    display: flex;
                    margin-top: 74px;
                    margin-left: 40px;
                    flex-wrap: wrap;
                    .square {
                        width: 22px;
                        height: 20px;
                        background: #C8D0E0 0% 0% no-repeat padding-box;
                        border-radius: 2px;
                        opacity: 1;
                        margin-top: 5px;
                        margin-left: 15px;
                    } 
                    .line {
                        margin-left: 6px;
                        width: 180px;
                        height: 0px;
                        border: 1px solid #3D3D3D;
                        opacity: 1;
                        margin-top: 7px;
                    }  
                    .secondLine {
                        margin-left: 15px;
                        width: 228px;
                        height: 0px;
                        border: 1px solid #3D3D3D;
                        opacity: 1;
                        margin-top: -6px;
                    }
                    .dottedLine {
                        width: 228px;
                        border: 1px dashed #3D3D3D;
                        opacity: 1;
                        margin-left: 15px;
                        margin-top: -10px;
                    }             
                }
            }
        }
    }
    .tagContainer {
        margin-top: -30px;
        margin-bottom: 32px;
        .tagLine {
            font: normal normal 600 16px/21px Segoe UI;
            letter-spacing: 0px;
            color: #3D3D3D;
            margin-bottom: 12px;
        }
        .MuiOutlinedInput-input {
            width: 759px;
            padding-right: 16px;
            padding-left: 16px;
            padding-top: 13px;
            padding-bottom: 13px;
            font: normal normal normal 14px/19px Segoe UI;
            letter-spacing: 0px;
            color: #000000;
        }
    }
    .imageContainer{
        margin-top: 32px;
        .uploadText {
            font: normal normal 600 16px/21px Segoe UI;
            letter-spacing: 0px;
            color: #3D3D3D;
            opacity: 1;
            margin-bottom: -12px;
        }
        .uploadDesc {
            font: italic normal 400 12px/16px Segoe UI;
            letter-spacing: 0px;
            color: #ADADAD;
            opacity: 1;
        }
        .uploadvalidation {
            font: italic normal 400 12px/16px Segoe UI;
            letter-spacing: 0px;
            color: #ADADAD;
            opacity: 1;
            margin-top: 70px;
            margin-left: -38%;          
        }
        .fileBox {
            width: 588px;
            height: 50px;
            display: flex;
            border: 0.5px solid #CFC0D4;
            border-radius: 6px;
            float: left;
            .labelContainer {
                width: 140px;
                text-align: center;
                height: 50px;
                border: 0.5px solid #E6EAF8;
                border-radius: 6px;
                cursor: pointer;
                background-color: #E6EAF8;
                display: flex;
                text-transform: capitalize;
                img {
                    width: 14px;
                    height: 14px;
                    opacity: 1;
                    margin-right: 8px;
                }
                .imgLabel {
                    font: normal normal 600 18px/24px Segoe UI;
                    letter-spacing: 0px;
                    color: #3D3D3D;
                    opacity: 1;
                    margin-top: 13px;
                    margin-right: 33px;
                    margin-left: 57px;
                    margin-bottom: 13px;
                    position: absolute;
                    cursor: "pointer",
                }
            }
            img.imageIcon {
                width: 17px;
                height: 13px;
                margin-top: 18px;
                margin-left: 45px;
            }
            p.imgName {
                font: normal normal normal 12px/16px Segoe UI;
                letter-spacing: 0px;
                color: #3D3D3D;
                opacity: 1;
                margin-top: 16px;
                margin-left: 12px;
            }
            .buttonCircle {
                width: 18px;
                height: 18px;
                background: #0C2E70 0% 0% no-repeat padding-box;
                opacity: 1;
                margin: 16px;
                border-radius: 15px;
                color: #FFFFFF;
                .icon {
                    width: 12px;
                    height: 12px;
                    transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
                    margin-top: -1px;
                    margin-left: 8.5px;
                    &:hover {
                        cursor: pointer;
                    }
                }
                &:hover {
                    cursor: pointer;
                }   
            }
        }
        .displayImage {
            margin-left: 370px;
            width: 187px;
            height: 103px;
            margin-top: -50px;
        }
        .displayNoImage {
            margin-left: 370px;
            width: 187px;
            height: 103px;
            margin-top: -50px;
            background-color: #F3F3F3;
        }
        p.fileErrorText {
            font-size: 13px;
            margin-top: -84px;
            margin-left: 16px;
            color: #F44336;
        }
    }
    .previewText {
        text-decoration: underline;
        font: normal normal normal 20px/27px Segoe UI;
        letter-spacing: 0px;
        color: #1447D4;
        opacity: 1;
        margin-bottom: 16px;
        margin-top: 35px;
        &:hover {
            cursor: pointer;
        }
    }
    .layoutTypeContainer {
        width: 1480px;
        background-color: #4A8409;
        margin-right: 64px;
        margin-bottom: 100px;
        display: flex;
        img.logo {
            width: 32px;
            height: 32px;
            align-self: center;
            margin-left: 36px;
            margin-right: 16px;
        }
        p.headerName {
            font: normal normal bold 24px/29px Segoe UI;
            letter-spacing: 1.2px;
            color: #FFFFFF;
            text-transform: uppercase;
            opacity: 1;
            align-self: center;
            //margin-bottom: -20px;
        }
        p.tagLineText {
            font: normal normal normal 20px/27px Segoe UI;
            letter-spacing: 1px;
            color: #FFFFFF;
            text-transform: capitalize;
            align-self: center;
            margin-top: -25px;
        }
        .headerRight {
            display: flex;
            position: absolute;
            right: 5%;
            align-self: center;
            img.notificationIcon {
                width: 28px;
                height: 28px;
                align-self: center;
                margin-right: 32px;
            }
            img.profile {
                width: 44px;
                height: 44px;
                align-self: center;
                margin-right: 12px;
            }
            p.adminText {
                font: normal normal 600 16px/21px Segoe UI;
                letter-spacing: 0px;
                color: #FFFFFF;
                align-self: center;
                margin-right: 12px;
            }
            .arrowDown {
                color: white;
                align-self: center;
                margin-right: 54px;
            }
        }
    }
    .extendedlayoutContainer {
        width: 1480px;
        background-color: #4A8409;
        div.headerTop {
            height: 80px;
            display: flex;
            background-color: #F3F3F3;
            .logo {
                width: 48px;
                height: 48px;
                margin-left: 36px;
                margin-top: 16px;
                margin-bottom: 16px;
            }
            .headerName {
                font: normal normal bold 24px/32px Segoe UI;
                letter-spacing: 1.2px;
                color: #FFFFFF;
                text-transform: uppercase;
                margin-left: 16px;
            }
            .thumbnail {
                margin-left: 300px;
                width: 190px;
                height: 80px;
            }
        }
        .tagLineText {
            height: 40px;
            align-items: center;
            font: normal normal normal 20px/27px Segoe UI;
            letter-spacing: 1px;
            color: #FFFFFF;
            margin-left: 36px;
            margin-top: 5px;
            text-transform: capitalize;
        }
    }
    .buttons{
        margin-left: 65%;
        // margin-top: 110px;
        margin-bottom: 48px;
        .cancelButton {
          text-transform: capitalize;
          width: 102px;
          height: 42px;
          background: #DFE2E8 0% 0% no-repeat padding-box;
          border-radius: 21px;
          font: normal normal 600 18px/24px Segoe UI;
          letter-spacing: 0px;
          color: #3D3D3D;
          margin-right: 30px;
        }
        .saveButton {
          text-transform: capitalize;
          width: 86px;
          height: 42px;
          background: #4A8409 0% 0% no-repeat padding-box;
          border-radius: 21px;
          font: normal normal 600 18px/24px Segoe UI;
          letter-spacing: 0px;
          color: #FFFFFF;
        }
      }

}