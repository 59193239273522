.testAppointment-container {
  height: 44rem;
  position: relative;
  .testAppointment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2rem 3.75rem 1rem 3.75rem;
    font: normal normal 600 $text-xlPlusTwo $text-font-family;

    .deleteIconTest {
      margin-left: 60rem;
      cursor: pointer;
    }
    .deleteIconTestAppointment {
      margin-left: 62rem;
    }

    .testAppointment-left {
      text-align: left;
      font-weight: 600;
      font-size: $text-lg;
      letter-spacing: 0px;
      color: $primary-black;
      text-transform: capitalize;
      opacity: 0.8;
    }

    .newMaterialBtnAppoint {
      text-transform: none;
      background-color: $primary-green;
      text-align: left;
      font-size: $text-lg;
      font-family: $text-font-family;
      letter-spacing: 0px;
      color: $primary-white;
      border-radius: 21px;
      padding: 6px 16px;
    }
  }
  .testAppointmentList_table_container {
    margin: 0.5rem 3.75rem 1rem 3.75rem;
    width: 92%;
    .testAppointmentList_table {
      .testAppointmentListBody {
        input[type="checkbox"] {
          position: relative;
          cursor: pointer;
          background-color: white;
        }
        input[type="checkbox"]:before {
          content: "";
          display: block;
          position: absolute;
          width: 16px;
          height: 16px;
          top: 0;
          left: 0;
          border: 1px solid #8a8a8a;
          border-radius: 2px;
          background-color: white;
        }
        input[type="checkbox"]:checked:before {
          content: "";
          display: block;
          position: absolute;
          width: 16px;
          height: 16px;
          top: 0;
          left: 0;
          background-color: #b9b9b9;
        }
        input[type="checkbox"]:checked:after {
          content: "";
          display: block;
          width: 5px;
          height: 10px;
          border: solid black;
          border-width: 0 1px 1px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
          position: absolute;
          top: 2px;
          left: 6px;
        }
        .bodyCell {
          padding: 1rem;
          text-align: left;
          color: #2f3543;
          font-size: $text-lg;
          font-family: $text-font-family;
          letter-spacing: 0px;
          max-width: 12.5rem;
        }
        .bodyCell.titleCell {
          font-weight: 600;
        }

        .bodyCell.overflowHidden {
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .bodyCell.testAppointmentDescription {
          font-size: $text-lg;
          color: $primary-black;
        }
        .editnDel {
          color: #1447d4;
          font-size: $text-lg;
        }
      }
      .testAppointmentListHeader {
        input[type="checkbox"] {
          position: relative;
          cursor: pointer;
          background-color: white;
        }
        input[type="checkbox"]:before {
          content: "";
          display: block;
          position: absolute;
          width: 16px;
          height: 16px;
          top: 0;
          left: 0;
          border: 1px solid #8a8a8a;
          border-radius: 2px;
          background-color: white;
        }
        input[type="checkbox"]:checked:before {
          content: "";
          display: block;
          position: absolute;
          width: 16px;
          height: 16px;
          top: 0;
          left: 0;
          background-color: #b9b9b9;
        }
        input[type="checkbox"]:checked:after {
          content: "";
          display: block;
          width: 5px;
          height: 10px;
          border: solid black;
          border-width: 0 1px 1px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
          position: absolute;
          top: 2px;
          left: 6px;
        }
        .headerCell {
          text-align: left;
          font-weight: normal;
          font-size: $text-lg;
          font-family: $text-font-family;
          letter-spacing: 0px;
          color: $primary-gray;

          svg {
            margin-left: 0.75rem;
          }
        }
      }
    }
  }
}
.testAppointmentContainer {
  .testAppointmentTitle {
    padding: 36px 25px 5px 36px;
  }
  .testAppointmentContent {
    padding: 6px 36px;
    .helpertextColor {
      color: #8a8a8a;
    }
    .testAppointment-text {
      text-align: left;
      font-size: $text-base;
      letter-spacing: 0px;
      color: #3d3d3d;
      font-weight: 600;
      margin-top: 0.5rem !important;
      margin-bottom: 0.25rem !important;
    }
    .testAppointmentText-box {
      background: $primary-white;
      width: 650px;
      border-color: #c8d0e0;
    }
    .keyBoardPickerWidth {
      width: 650px;
      background: $primary-white;
      border: 1px solid #cfc0d4;
      border-radius: 4px;
      .MuiFormHelperText-contained {
        display: none;
      }
    }
    .testAppointmentText-box2 {
      background: $primary-white;
      box-shadow: 0px 6px 9px #0000000f;
      border: 1px solid #cfc0d4;
      border-radius: 4px;
    }
  }
  .btnClick {
    padding: 8px 30px 36px 8px;
  }
}
.deleteDialogtestAppointment {
  height: 225px;

  .titleDeletetestAppointment {
    margin-top: 20px;
    text-align: left;
    font-size: $text-xl;
    letter-spacing: 0px;
    color: $primary-black;
    opacity: 1;
    padding-left: 36px;
    .crossBtntestAppointment {
      margin-left: 10px;
    }
  }
  .contentTexttestAppointment {
    font-size: $text-base;
    letter-spacing: 0px;
    color: #3d3d3d;
    opacity: 1;
    margin-left: 13px;
    .textBoldtestAppointment {
      font-weight: 600;
    }
  }
  .crossBtntestAppointment {
    position: fixed;
    right: 38%;
  }

  .okBtnStyletestAppointment {
    border-radius: 21px;
    background-color: $primary-green;
    text-transform: none;
    color: $primary-white;
    font-size: $text-base;
    font-weight: 600;
  }
  .cancelBtnStyletestAppointment {
    border-radius: 21px;
    background-color: #adadad73;
    text-transform: none;
    color: $primary-black;
    font-size: $text-base;
    font-weight: 600;
  }
  .deleteMaterialDialogtestAppointment {
    position: relative;
    left: 20%;
    bottom: 2%;
  }
}

.selectFirstChild {
  color: $gray;
  opacity: 1;
  font-size: $text-base;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
  .MuiOutlinedInput-notchedOutline {
  border-color: #c8d0e0 !important;
}
