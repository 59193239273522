// Primary colors
$primary-blue: #0c2e70;
$primary-gray: #8a8a8a;
$primary-black: #000;
$primary-black--transparent: rgba(0, 0, 0, 0.6);
$primary-white: #fff;
$primary-green: #4a8409;

// Secondary colors
$blue: #00c4f8;
$gray: #adadad;
$blue--transparent: rgba(131, 225, 252, 0.8);
$hover-state: #0058ad;
$active-state: #063769;
$light-blue: #d9ebf9;
$turquoise: #297a80;
$green: #2d6c2b;
$purple: #6a228b;
$magenta: #aa1550;
$red: #d9534f;
$orange: #dd4a19;
$error-state: #b51809;
$passed: #007730;
$labelcolor: #3d3d3d;
$floral_white:#FFF9E7;
$ghost_white:#F7F8FC;
$burgundy:#18760A;
$solitute:#E6EAF8;
$nightRider:#2E2E2E;
$extraWhite:#e9f7e5;
$yellowOrce:#f9f9e6;
$corral:#70590d;
$redWarm:#f9e9e8;
$forestGreen:#972424;
$grey:#a8a8a8;
$whiteSmoke:#efefef;
$fordGrey:#979797;
$darkGreen:#199D05;
$americanSilver:#cfcfcf;
$coolBlue:#C8D0E0;
$separator:#DDE3EC;
$blueTitle:#1447D4;
$persianRed:#CF2A2A;
$burntSienna:#EC5959;
$prestigeBlue:#2f3543;
$boxBorder:#adadad6e;
$rockCrystal:#cdc9c9;
$lightGrey:#b9b9b9;
$summaryBorderBottom:rgb(173, 173, 173, 0.5);
$bgBoxVideo:#7070704d;
$wildSand:#dfe2e8;
$videoButton:#b9b9b966;
$matterHorn:#4b4b4b;
$maire:#1A051D;
$whiteSmokeShade:#F5F5F5;
$skyBlue:#2298e6;
$greyLightShade:#ECEBED;
$matterHornShade:#525252;
$blackBoxShadow:rgba(0, 0, 0, 0.078);
$blackBoxShadowList:rgba(0, 0, 0, 0.271);
$persianRedLight:#d32f2f;



// font-size
$text-xs: 0.75rem; // 12px
$text-sm: 0.875rem; // 14px
$text-md: 0.938rem; // 15px
$text-base: 1rem; // 16px
$text-basePlusOne: 17px;
$text-lg: 1.125rem; // 18px
$text-xl: 1.25rem; // 20px
$text-xlPlusTwo: 1.375rem; // 22px
$text-2xl: 1.5rem; // 24px
$text-3xl: 1.875rem; // 30px
$text-4xl: 2rem; // 32px;
$text-5xl: 2.25rem; // 36px
$text-6xl: 3rem; // 48px


// font-family
$text-font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;

//letter spacing
$text-letter-spacing: 0px;
$text-letter-spacing-l: 1.2px;

html * {
  font-family: $text-font-family;
}
