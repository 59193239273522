.audioVideoSettings {
  .audioVideoSettings_table_container {
    margin: 0.5rem 3.75rem 1rem 3.75rem;
    width: 92%;

    .audioVideoSettingsHeader {
      .headerCell {
        text-align: left;
        font-size: $text-lg;
        font-family: $text-font-family;
        letter-spacing: 0px;
        color: $primary-gray;
        text-transform: capitalize;
        opacity: 0.8;
        border-bottom: none;
        font-weight: 400;
      }
    }
    .audioVideoSettingsBody {
      .bodyCell {
        border-bottom: none;
        text-align: left;
        font-size: $text-base;
        font-family: $text-font-family;
        letter-spacing: 0px;
        color: #2f3543;
        padding: 0.75rem;
      }
    }
  }
}
