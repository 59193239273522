.summaryTableMain{
  padding: 0px 60px 0px 60px;

.summaryTable{
max-height: 360px;;
  margin-top: 24px;
    margin-bottom: 120px;
.appointmentListHeader {
    .headerCell {
      color: $primary-gray;
      font-size: $text-lg;
      text-align: left;
      &.sortIcon {
        cursor: pointer;
        .sort-icons {
          margin-left: 0.75rem;
        }
        
      }
    }
  }

  .appointmentListBody {
    .bodyCell {
      color: $labelcolor;
      font-size: $text-lg;
    }
    .smallFont {
      font-size: $text-sm;
    }
    .bold {
      font-weight: 600;
    }
  }


  .statusColor {
    padding: 5px 10px;
    border-radius: 4px;
  }


  .inProgress {
  background-color: $extraWhite;
  color: $burgundy;
}
.waiting {
  background-color: $yellowOrce;
  color: $corral;
}
.delayed {
  background-color: $redWarm;
  color: $forestGreen;
}
.completed {
  background-color: $grey;
  color: $primary-white;
}
.scheduled {
  background-color: $whiteSmoke;
  color: $fordGrey;
}


}

}

.noResultSummary{
  margin-right: 0.5rem;

  .noResultListView {
    display: flex;
    align-items: center;
    font-weight: bold;
    color: $labelcolor;
    font-size: $text-lg;
    margin-top: 20px;
    margin-left: 60px;
  
    svg {
      margin-right: 10px;
    }
  }
}