.summaryDetailMain{

    padding: 16px 60px 16px 60px;

    .navigation-buttons {
        margin-bottom: 2.5rem;
        .noWrap {
          flex-wrap: nowrap;
        }
        .button {
          .back-icon,
          .previous-icon,
          .next-icon {
            width: 8px;
            height: 20px;
            margin-right: 1rem;
          }
          .previous-icon {
            margin-right: 0.5rem;
          }
          .next-icon {
            margin-left: 0.5rem;
          }
          .back {
            &.text {
              font-size: $text-xlPlusTwo;
              font-weight: 600;
              color: $primary-black;
              cursor: pointer;
            }
          }
          &.nextAndPrevious {
            .disabled {
              cursor: not-allowed;
              .text {
                color: #adadad;
                cursor: not-allowed !important;
              }
            }
            .previous {
              &.text {
                margin-right: 1rem;
              }
            }
            .text {
              font-size: $text-xlPlusTwo;
              color: $primary-black;
              font-weight: 600;
              cursor: pointer;
            }
          }
        }
      }



      .appointment {
        .patient-id,
        .schedule {
          margin-right: 6rem;
        }
        .duration {
          margin-left: 6rem;
        }
        .header {
          font-size: $text-sm;
          color: $primary-gray;
        }
        .text {
          font-size: $text-base;
          color: #1e2123;
        }
      }


      .details {
        .details-container {
          margin-bottom: 1rem;
          align-items: center;
          .patient {
            font-size: $text-xl;
            font-weight: 600;
            margin-right: 6rem;
          }
       
        }
      }


      .inProgress {
        background-color: $extraWhite;
        color: $burgundy;
      }
      .waiting {
        background-color: $yellowOrce;
        color: $corral;
      }
      .delayed {
        background-color: $redWarm;
        color: $forestGreen;
      }
      .completed {
        background-color: $grey;
        color: $primary-white;
      }
      .scheduled {
        background-color: $whiteSmoke;
        color: $fordGrey;
      }

      .status {
        display: inline-block;
        padding: 0.25rem 0.75rem;
        font-size: $text-basePlusOne;
        border-radius: 4px;
      }


      .verticalStepper{
       

        .MuiStepConnector-vertical{
          padding:0px;
        }
        .stepCompleted{
          .MuiStepContent-root{
            margin-top:0px;
            border-left: 1px solid $burgundy;
          }
          .MuiStepConnector-line {
            display: block;
            border-color: $darkGreen;
          }
        }

        .stepCompleted+ div{
          .MuiStepConnector-line {
            display: block;
            border-color: $burgundy;
    
        }
        }


        .stepNextError {
          .MuiStepContent-root{
            margin-top:0px;
            border-left: 1px solid $burntSienna;
          }

          .stepErrorLabel {
            margin-left: 3px;
          }
         }
        .stepNextError + div{
          .MuiStepConnector-line {
              display: block;
              border-color: $burntSienna;
      
          }
         
       }

       

       .stepError{
        .MuiStepConnector-line {
            display: block;
            border-color: $burntSienna;
    
        }
        .MuiStepContent-root{
          margin-top:0px;
        }
      
      .stepLable{
          svg{
            // color: white;
            // background-color: $burntSienna;
            // border-radius: 5rem;
            // font-size: medium;
            // height: 0.6rem;
            // padding: 0.2rem 0rem 0.2rem 0rem;
            // margin-left: 0.3rem;


            color: $burntSienna;
            background-color: $primary-white;
            font-size: large;
            margin-left: 0.24rem;
          }
      }
     }

       .stepDisabled{
        .MuiStepConnector-line {
            display: block;
            border-color: $primary-gray;
    
        }
        .MuiStepContent-root{
          margin-top:0px;
        }

        .MuiStepIcon-text{
          visibility: hidden;
      }
     }
  
      }
      
}