.LogoMain {
    margin-top: 125px;
    margin-left: 41px;
    .headerLogo {
        padding-top: 30px;
        font: normal normal 600 20px/27px $text-font-family;
        letter-spacing: 0px;
        color: $primary-blue;
    }

    .title{
        font: normal normal 600 16px/21px $text-font-family;
        color: $labelcolor;
        margin-top: 24px;
    }

    .desc{
        font: italic normal 400 12px/16px $text-font-family;
        color: $gray;
    }


    .applicationTitle{
        font: normal normal 600 16px/21px $text-font-family;
        color: $labelcolor;
    }


    .applicationTextBox{
        border: 0.5px solid $gray;
        border-radius: 4px;
        width: 48%;
        margin-top: 16px;
        div{
            height: 45px;
        }
    }


    .dropDragPOC{  
        flex: 1 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 40px;
        border-width: 2px;
        border-radius: 9px;
        border-color: $boxBorder;
        border-style: dashed;
        background-color: $ghost_white;
        color: $gray;
        outline: none;
        width: 48%;
        transition: border 0.24s ease-in-out;
        margin-top: 24px;
        margin-bottom: 3%;
        height: 175px;
        .PreviewError{
          margin-left: 40px;
          .progressbarsErrorPreview{
            width: 520px;
            height: 4px;
            background: $primary-green 0% 0% no-repeat padding-box;
            border-radius: 11px;
            opacity: 1;
          }
          .closePreviewError{
            color: $persianRedLight;
            margin-left: 40px;
            margin-top: -10px;
            cursor: pointer;
          }
          .textError{
            margin-left: 100px;
          }
        }
      .imgSet{
        .dropDragText {
          padding-top: 15px;
          margin-bottom: 0;
          margin-top: 0;
          margin-left: 200px;
          margin-bottom: 6px;
        }
        .dropDragSubTitle {
          padding-top: 8px;
          font-size: $text-xs;
          margin-top: 0;
          margin-left: 100px;
        }
      }
      .dispNone{
        display: none;
      }
      
      }

    

    .buttons{
        margin-left: 75%;
        margin-top: 45px;
        .cancelButton {
          text-transform: capitalize;
          width: 102px;
          height: 42px;
          background: $wildSand 0% 0% no-repeat padding-box;
          border-radius: 21px;
          font: normal normal 600 18px/24px $text-font-family;
          letter-spacing: 0px;
          color: $labelcolor;
          margin-right: 30px;
        }
        .saveButton {
          text-transform: capitalize;
          width: 156px;
          height: 42px;
          background: $primary-green 0% 0% no-repeat padding-box;
          border-radius: 21px;
          font: normal normal 600 18px/24px $text-font-family;
          letter-spacing: 0px;
          color: $primary-white;
          margin-right: 64px;
        }
      }
}