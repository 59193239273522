.video-container {
    height: 44rem;
    position: relative;
    .video {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 2rem 3.75rem 1rem 3.75rem;
      font: normal normal 600 $text-xlPlusTwo $text-font-family;
  
      .video-left {
        text-align: left;
        font-weight: 600;
        font-size: $text-xl;
        letter-spacing: 0px;
        color: $primary-black;
        text-transform: capitalize;
      }
  
      .newVideoBtn {
        text-transform: none;
        background-color: $primary-green;
        text-align: left;
        font-size: $text-lg;
        font-family: $text-font-family;
        letter-spacing: 0px;
        color: $primary-white;
        border-radius: 21px;
        padding: 6px 16px;
      }
  
      .VideoBack {
        cursor: pointer;
        display: flex;
        align-items: center;
        img {
          margin-right: 10px;
        }
      }
      .dialog-box {
        width: 50%;
        height: 50%;
      }
    }
  
    .videoList_table_container {
      margin: 0.5rem 3.75rem 1rem 3.75rem;
      width: 92%;
      height: 75%;
      .videoList_table {
        .videoListBody {
          .bodyCell {
            padding: 1rem;
            text-align: left;
            color: $prestigeBlue;
            font-size: $text-lg;
            font-family: $text-font-family;
            letter-spacing: 0px;
            max-width: 12.5rem;
          }
          .bodyCell.titleCell {
            font-weight: 600;
          }
  
          .bodyCell.overflowHidden {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .videoListHeader {
          .headerCell {
            text-align: left;
            font-weight: 600;
            font-size: $text-lg;
            font-family: $text-font-family;
            letter-spacing: 0px;
            color: $primary-gray;
  
            svg {
              margin-left: 0.75rem;
            }
          }
        }
      }
    }
  }


  .viewVideoContainer {
    padding: 2.25rem;
    min-height: 778px;
    display: flex;
    flex-direction: column !important;
    justify-content: space-between;
    .viewVideoTitle {
      font-weight: 600;
      color: $primary-black;
      font-size: $text-xl;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px;
    }
  
    .UploadfileName {
      margin-top: 7rem;
      color: $blueTitle;
      font-size: $text-base;
      margin-right: 5px;
    }
    .viewVideo {
      color: $primary-gray;
      opacity: 1;
      font-size: $text-sm;
    }
    .viewDescription {
      margin-top: 2rem;
      color: $prestigeBlue;
      font-size: $text-base;
      display: flex;
      justify-content: space-between;
  
      .viewImage {
        margin-left: 1.5rem;
      }
    }
  }




  

.videoRedBox {
  background: $primary-white 0% 0% no-repeat padding-box;
  border: 1px solid $persianRed;
  border-radius: 6px;
  opacity: 1;
  outline: none;
  .dropDrag {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    font-weight: 450;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 9px;
    border-color: $boxBorder;
    border-style: dashed;
    background-color: $ghost_white;
    color: $gray;
    outline: none;
    width: 94%;
    transition: border 0.24s ease-in-out;
    margin-left: 3%;
    margin-top: 3%;
    margin-bottom: 3%;
    .progressbar {
      background: $rockCrystal;
      border-radius: 11px;
    }
  }
}
.videoText-box {
  background: $primary-white 0% 0% no-repeat padding-box;
  border: 1px solid $lightGrey;
  border-radius: 6px;
  opacity: 1;
  outline: none;
 
  .thumbNailText{
    font: normal normal normal 16px/21px $text-font-family;
    color: $primary-gray;
    margin: 23px 0px 0px 24px;
  }
  .fileUpload {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 18px 16px 18px 16px;
    border-width: 2px;
    border-radius: 9px;
    border-color: #adadad6e;
    border-style: dashed;
    background-color: #f7f8fc;
    color: $blueTitle;
    text-decoration: underline;
    outline: none;
    transition: border 0.24s ease-in-out;
    margin-left: 24px;
    margin-top: 3%;
    margin-bottom: 3%;
    margin-right: 24px;
    .crossbtnUploadFile {
      margin-right: 25px;
      color: #212121;
      width: 19px;
      height: 19px;
    }
    .progressbars {
      width: 471px;
      height: 4px;
      background: $primary-green 0% 0% no-repeat padding-box;
      border-radius: 11px;
      opacity: 1;
    }
  }

  .videoURL {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 18px 16px 18px 16px;
    border-width: 2px;
    border-radius: 9px;
    border-color: $boxBorder;
    border-style: dashed;
    background-color: $ghost_white;
    color: $blueTitle;
    outline: none;
    transition: border 0.24s ease-in-out;
    margin-left: 24px;
    margin-top: 8px;
    margin-bottom: 3%;
    margin-right: 24px;
   
  }
  .dropDrag {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 9px;
    border-color: $boxBorder;
    border-style: dashed;
    background-color: $ghost_white;
    color: $gray;
    outline: none;
    width: 94%;
    transition: border 0.24s ease-in-out;
    margin-left: 3%;
    margin-top: 3%;
    margin-bottom: 3%;
    height: 120px;

    .dropDragText {
      padding-top: 25px;
      margin-bottom: 0;
      margin-top: 0;
    }
    .dropDragSubTitle {
      padding-top: 8px;
      font-size: $text-xs;
      margin-top: 0;
    }
  }
  .progressbar {
    background: $rockCrystal;
    border-radius: 11px;
  }
  .MuiLinearProgress-barColorPrimary {
    background-color: $primary-green;
  }
}

.video-text {
  text-align: left;
  font: normal normal 600 $text-base $text-font-family;
  letter-spacing: 0px;
  color: $matterHorn;
  margin-top: 1rem;
  margin-bottom: 0.25rem;
}

.video-title {
  color: $persianRed;
}

.video-error {
  color: $persianRed;
  font-size: $text-xs;
}

.uploadbtnVideo {
  height: 50px;
  width: 385px;
  border: 1px solid $videoButton;
  border-radius: 6px;
  opacity: 1;
  position: absolute;
  text-align: left;
  font: normal normal 600 $text-lg $text-font-family;
  letter-spacing: 0px;
  color: $blueTitle;
  background: linear-gradient(to right, #f0f4f9 37%, white 0%);
  .thumbnailFilename {
    position: relative;
    bottom: 7%;
    font-size: 13px;
    .thumbnailcrossbtn {
      width: 12px;
      position: relative;
      top: 9px;
      left: 5px;
    }
  }
  .fileName {
    margin-left: 5rem;
    font-size: 13px;
    color: $blueTitle;
  }
  .defaultfileName {
    margin-left: 5rem;
    font-size: $text-sm;
    color: $gray;
  }
}
.uploadArrowVideo {
  margin-right: 7px;
  margin-left: 14px;
  margin-top: 18px;
}

.backgroundBoxVideo {
  top: 72%;
  width: 200px;
  height: 120px;
  position: absolute;
  background: $wildSand 0% 0% no-repeat padding-box;
  border: 1px solid $bgBoxVideo;
  border-radius: 4px;
  opacity: 1;
  margin-left: 29.5rem;
  .fileInfo {
    position: relative;
    bottom: 32%;
    margin-left: 58px;
    font-size: $text-xs;
    font-style: italic;
    color: $gray;
  }

  .textFile {
    font: normal normal 400 $text-sm $text-font-family;
    letter-spacing: 0px;
    color: $prestigeBlue;
    text-transform: uppercase;
    opacity: 1;
    position: absolute;
    top: 45%;
    left: 35%;
  }
}

.editSummary_nav_tabs_container{
  display: flex;
  align-items: center;
  margin-top: 16px ;
  margin-bottom: 32px ;
  border-bottom: 1px solid $summaryBorderBottom;

.editSummary_tab {
  text-transform: none;
  font-size: $text-lg;
  font-weight: 0;
  color: $primary-gray;
  margin-right: 3rem;
  height: 2.25rem;
  cursor: pointer;

  display: flex;
  align-items: center;
  padding-bottom: 0.25rem;
}
.editSummary_tab.active {
  color: $primary-black;
  font-weight: 600;
  border-bottom: 0.25rem solid $primary-green;
  svg.dashboard_tab_material_icon {
    path {
      fill: $primary-black;
    }
  }
  svg.noResult {
    path {
      fill: $primary-black;
    }
  }
  svg.dashboard_tab_Relaxation_icon {
    g {
      path {
        stroke: $primary-black;
      }
    }
  }
  svg.dashboard_tab_systemSettings_icon {
    g {
      path {
        stroke: $primary-black;
      }
      circle {
        stroke: $primary-black;
      }
      line {
        stroke: $primary-black;
      }
    }
  }
  svg.dashboard_tab_meetingSettings_icon {
    g {
      path {
        stroke: $primary-black;
      }
    }
  }
}
}