.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 3.75rem 1rem 3.75rem;
  height: 3.375rem;
  background: #f7f8fc 0% 0% no-repeat padding-box;
  border: 0.5px solid #adadadba;
  border-radius: 4px;
  padding: 0.75rem 2rem;
  position: absolute;
  width: 92%;
  bottom: 0;

  .pagination-left {
    text-align: left;
    font-size: $text-xl;
    letter-spacing: 0px;
    color: $primary-gray;
  }

  .pagination-right {
    display: flex;
    align-items: center;
    text-align: left;
    font-size: $text-xl;
    letter-spacing: 0px;
    color: $primary-blue;
    .pagination-pageCount {
      display: flex;
      align-items: center;
      width: 4.063rem;
      margin-right: 2rem;
      height: 2.25rem;
      justify-content: space-evenly;

      span {
        position: relative;
        bottom: 3px;
      }

      .MuiOutlinedInput-root {
        height: 2.25rem;
      }
    }

    .borderCls {
      border: 1px solid #adadad69;
      border-radius: 4px;
    }
    .borderCls.arrowCls {
      width: 2.813rem;
      margin-right: 2rem;
      height: 2.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .borderCls.arrowCls.disabled {
      cursor: not-allowed;
    }

    .pagination-straightLine {
      font-size: $text-2xl;
      margin-right: 2rem;
      height: 2.25rem;
      border: 0.5px solid #707070;
    }
  }
}
