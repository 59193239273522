.stepper{
    .stepCompleted{
        .MuiStepConnector-alternativeLabel{
            top: 12px;
        left: calc(-50% + 12px);
        right: calc(50% + 12px);
        position: absolute;
    
        .MuiStepConnector-lineHorizontal {
            border-top-style: solid;
            border-top-width: 3px;
    
        }
        .MuiStepConnector-line {
            display: block;
            border-color: $darkGreen;
    
        }
    }
}

.stepError{
    .MuiStepConnector-alternativeLabel{
        top: 12px;
    left: calc(-50% + 12px);
    right: calc(50% + 12px);
    position: absolute;

    .MuiStepConnector-lineHorizontal {
        border-top-style: solid;
        border-top-width: 3px;

    }
    .MuiStepConnector-line {
        display: block;
        border-color: $burntSienna;

    }
}

.stepLable{
    svg{
    color: white;
    background-color: $burntSienna;
    border-radius: 5rem;
    padding: 0.3rem;
    font-size: medium;
    }
}
}


    .stepDisabled{
        .MuiStepConnector-alternativeLabel{
            top: 12px;
        left: calc(-50% + 12px);
        right: calc(50% + 12px);
        position: absolute;
    
        .MuiStepConnector-lineHorizontal {
            border-top-style: solid;
            border-top-width: 3px;
    
        }
        .MuiStepConnector-line {
            display: block;
            border-color: $primary-gray;
        }
    }
    .MuiStepIcon-text{
        visibility: hidden;
    }
}
    
    .stepsInProgress{
    .MuiStepConnector-alternativeLabel{
        top: 12px;
    left: calc(-50% + 12px);
    right: calc(50% + 12px);
    position: absolute;

    .MuiStepConnector-lineHorizontal {
        border-top-style: solid;
        border-top-width: 3px;

    }
    .MuiStepConnector-line {
        display: block;
    border-image: linear-gradient( to right, $burgundy 0%, $americanSilver 60% ) 21 30 30 21;

    }
}



    }
}