.notification-container {
  height: 44rem;
  position: relative;
  .notification {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 0;
    font-weight: 600;
    font-size: $text-xlPlusTwo;

    .notification-left {
      text-align: left;

      font-weight: 600;
      font-size: $text-xl;
      letter-spacing: $text-letter-spacing;
      color: $primary-black;
      text-transform: capitalize;
    }

    .newMaterialBtn {
      text-transform: none;
      background-color: $primary-green;
      text-align: left;
      font-size: $text-lg;
      font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
      letter-spacing: $text-letter-spacing;
      color: $primary-white;
      border-radius: 21px;
      position: relative;
      bottom: 1.5rem;
      right: 4rem;
      padding: 6px 16px;
    }
  }
  .notificationList_table_container {
    margin: 0.5rem 3.75rem 1rem 3.75rem;
    width: 92%;
    .notificationList_table {
      .notificationListBody {
        .bodyCell {
          padding: 1rem;
          text-align: left;
          font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
          color: #2f3543;
          font-size: $text-lg;
          letter-spacing: $text-letter-spacing;
          max-width: 12.5rem;
        }
        .bodyCell.titleCell {
          font-weight: 400;
        }

        .bodyCell.overflowHidden {
          // white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .bodyCell.notificationDescription {
          font-size: $text-lg;
          color: $primary-black;
        }
        .editnDel {
          color: #1447d4;
          font-size: $text-lg;
        }
      }
      .notificationListHeader {
        .headerCell {
          text-align: left;
          font-weight: normal;
          font-size: $text-lg;
          letter-spacing: $text-letter-spacing;
          color: $primary-gray;
          opacity: 1;

          svg {
            margin-left: 0.75rem;
          }
        }
      }
    }
  }
}
.notificationContainer {
  .notificationTitle {
    padding: 36px 25px 5px 36px;
  }
  .notificationContent {
    padding: 5px 36px;
    height: 483px;
    flex: 0 0 auto;
    .notification-text {
      text-align: left;
      font-size: $text-base;
      letter-spacing: $text-letter-spacing;
      color: $labelcolor;
      font-weight: 600;
      margin-top: 0.5rem;
      margin-bottom: 0.25rem;
    }
    .editor-wrapper {
      position: relative;
    }
    .ql-toolbar {
      position: absolute;
      bottom: 0;
      width: 100%;
      transform: translateY(100%);
    }
    .notificationText-box {
      background: $primary-white;
      border: 1px solid #cfc0d4;
      border-radius: 4px;
      outline: none;
    }
    .dropdownRecipient {
      color: $gray;
      font-size: 16px;
    }
  }
  .btnClick {
    padding: 8px 30px 36px 8px;
  }
}
.deleteDialogNotification {
  // width: 525px;
  height: 225px;

  .titleDeleteNotification {
    margin-top: 20px;
    text-align: left;
    font-size: $text-xl;

    letter-spacing: $text-letter-spacing;
    color: $primary-black;
    opacity: 1;
    padding-left: 36px;
    .crossBtnNotification {
      margin-left: 10px;
    }
  }
  .contentTextNotification {
    font-size: $text-base;

    letter-spacing: $text-letter-spacing;
    color: $labelcolor;
    opacity: 1;
    margin-left: 13px;
    .textBoldNotification {
      font-weight: 600;
    }
  }
  .crossBtnNotification {
    position: fixed;
    right: 38%;
  }

  .okBtnStyleNotification {
    border-radius: 21px;
    background-color: $primary-green;
    text-transform: none;
    color: $primary-white;

    font-size: $text-base;
    font-weight: 600;
  }
  .cancelBtnStyleNotification {
    border-radius: 21px;
    background-color: $gray;
    text-transform: none;
    color: $primary-black;

    font-size: $text-base;
    font-weight: 600;
  }
  .deleteMaterialDialogNotification {
    position: relative;
    left: 20%;
    bottom: 2%;
  }
}
.emailBody-error {
  color: #cf2a2a;
  font-size: $text-xs;
  padding: 5px 36px;
}
.appointmentText {
  padding: 5px 36px;
  color: $primary-black;
  opacity: 1;
  font-size: $text-lg;
}
.buttonSpace {
  margin-left: 1rem;
}

.templatesButton {
  padding: 5px 36px;
  .buttonEmailWhiteSpace {
    background-color: $primary-white !important;
    border: 1px solid #cfc0d4 !important;
    border-radius: 0.25rem !important;
    color: $primary-black !important;
    opacity: 1 !important;
    padding: 0.5rem !important;
    margin-bottom: 1rem !important;
    margin-left: 1rem !important;
  }
  .buttonEmailWhite {
    background-color: $primary-white !important;
    border: 1px solid #cfc0d4 !important;
    border-radius: 0.25rem !important;
    color: $primary-black !important;
    opacity: 1 !important;
    padding: 0.5rem !important;
    margin-bottom: 1rem !important;
  }
  .buttonEmailGreenSpace {
    background-color: #e9f7e5 !important;
    color: #18760a !important;
    border: 1px solid #e9f7e5 !important;
    border-radius: 0.25rem !important;
    opacity: 1 !important;
    padding: 0.5rem !important;
    margin-bottom: 1rem !important;
    margin-left: 1rem !important;
  }
  .buttonEmailGreen {
    background-color: #e9f7e5 !important;
    color: #18760a !important;
    border: 1px solid #e9f7e5 !important;
    border-radius: 0.25rem !important;
    opacity: 1 !important;
    padding: 0.5rem !important;
    margin-bottom: 1rem !important;
  }
}
