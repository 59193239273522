.language-container {
  height: 44rem;
  position: relative;
  .language {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1.5rem 3.75rem 1.5rem 3.75rem;
    font: normal normal 600 $text-xlPlusTwo $text-font-family;

    .language-left {
      text-align: left;
      font-weight: 600;
      font-size: $text-lg;
      letter-spacing: 0px;
      color: $primary-gray;
      text-transform: capitalize;
      opacity: 0.8;
    }

    .newMaterialBtn {
      text-transform: none;
      background-color: $primary-green;
      text-align: left;
      font-size: $text-lg;
      font-family: $text-font-family;
      letter-spacing: 0px;
      color: $primary-white;
      border-radius: 21px;
      padding: 6px 16px;
    }
  }
  .languageList_table_container {
    margin: 0rem 3.75rem 1rem 3.75rem;
    width: 92%;
    .languageList_table {
      .languageListBody {
        .bodyCell {
          padding: 1rem;
          text-align: left;
          color: #2f3543;
          font-size: $text-lg;
          font-family: $text-font-family;
          letter-spacing: 0px;
          max-width: 12.5rem;
        }
        .bodyCell.titleCell {
          font-weight: 600;
        }

        .bodyCell.overflowHidden {
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .bodyCell.languageDescription {
          font-size: $text-lg;
          color: $primary-black;
        }
        .editnDel {
          color: #1447d4;
          font-size: $text-lg;
        }
      }
      .languageListHeader {
        .headerCell {
          text-align: left;
          font-weight: 600;
          font-size: $text-lg;
          font-family: $text-font-family;
          letter-spacing: 0px;
          color: $primary-gray;

          svg {
            margin-left: 0.75rem;
          }
        }
      }
    }
  }
}
.languageContainer {
  .languageTitle {
    padding: 36px 25px 5px 36px;
  }
  .languageContent {
    padding: 5px 36px;
    .language-text {
      text-align: left;
      font-size: $text-base;
      letter-spacing: 0px;
      color: #3d3d3d;
      font-weight: 600;
      margin-top: 0.5rem;
      margin-bottom: 0.25rem;
    }
    .languageText-box {
      background: $primary-white;
      border: 1px solid #cfc0d4;
      border-radius: 4px;
      outline: none;
      width: 650px;
    }
    .languageText-box2 {
      background: $primary-white;
      box-shadow: 0px 6px 9px #0000000f;
      border: 1px solid #cfc0d4;
      border-radius: 4px;
    }
  }
  .btnClick {
    padding: 8px 30px 36px 8px;
  }
}
.deleteDialogLanguage {
  // width: 525px;
  height: 225px;

  .titleDeleteLanguage {
    margin-top: 20px;
    text-align: left;
    font-size: $text-xl;
    letter-spacing: 0px;
    color: $primary-black;
    opacity: 1;
    padding-left: 36px;
    .crossBtnLanguage {
      margin-left: 10px;
    }
  }
  .contentTextLanguage {
    font-size: $text-base;
    letter-spacing: 0px;
    color: #3d3d3d;
    opacity: 1;
    margin-left: 13px;
    .textBoldLanguage {
      font-weight: 600;
    }
  }
  .crossBtnLanguage {
    position: fixed;
    right: 38%;
  }

  .okBtnStyleLanguage {
    border-radius: 21px;
    background-color: $primary-green;
    text-transform: none;
    color: $primary-white;
    font-size: $text-base;
    font-weight: 600;
  }
  .cancelBtnStyleLanguage {
    border-radius: 21px;
    background-color: #adadad73;
    text-transform: none;
    color: $primary-black;
    font-size: $text-base;
    font-weight: 600;
  }
  .deleteMaterialDialogLanguage {
    position: relative;
    left: 20%;
    bottom: 2%;
  }
}

.selectFirstChild {
  color: $gray;
  opacity: 1;
  font-size: $text-base;
}
